import React, { useState, useEffect } from 'react';
import SectionDescription from '../../../../components/section-description/section-description';
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { Grid, Box, Typography } from '@material-ui/core';
import ImageContainer from '../../../../components/image-container/index';
import ExhibitionJSON from '../../../../data/exhibition-2017.json';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter/index';
import ImageCarousel from '../../../../components/image-carousel/index';
import Gallery from '../../../../data/gallery.json';

export default function Exhibition(props) {
  const year = '2017';
  const album = 'youth-circle';
  const [files, setFiles] = useState();
  let albumFiles = [];

  useEffect(() => {
    Gallery[year].forEach((_yearAlbums) => {
      _yearAlbums.albums.forEach((_album) => {
        if (_album.name === album) {
          _album.filenames.forEach((_file) => {
            albumFiles.push(_file.filename);
          });
        }
      });
    });
    setFiles(albumFiles);
    return () => {};
    // eslint-disable-next-line
  }, []);

  //   useEffect((fl) => {
  //     setFiles(ExhibitionJSON.gallery.albums.filenames);
  //   }, []);

  return (
    <Layout>
      <SEO lang='en' title='Digital Series' />
      <SectionDescription
        left={true}
        image={true}
        filename='default'
        ctaText='Exhibition 2017'
        title='Exhibition 2017'
        paragraphText={ExhibitionJSON.paragraph}
        dataList={ExhibitionJSON.list}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box my={3}>
            <Typography variant='h6'>{ExhibitionJSON.partners.title}</Typography>
          </Box>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          {ExhibitionJSON.partners.partner &&
            ExhibitionJSON.partners.partner.length !== 0 &&
            ExhibitionJSON.partners.partner.map((ptr, idx) => {
              return (
                <Grid key={idx} item xs={12} md={6} lg={3}>
                  <ImageContainer filename={ptr.image} altText='#GMIS2021' />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Box clone mt={5}>
        <Grid container>
          <Grid item xs={12}>
            <SectionContainerLayoutWithFilter title='Gallery' isViewAll baseLink='/galleries'>
              {files ? <ImageCarousel imageFilenames={files} /> : <Typography paragraph>No images found</Typography>}
            </SectionContainerLayoutWithFilter>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
